/* body {
  background-color: #231f3a;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-weight: 200;
} */

#contactform {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 400px;
}

#contactform input,
#contactform textarea {
  border: 0;
  margin-bottom: 10px;
  padding: 20px;
  font-size: 17px;
  background-color: transparent;
  color: white;
  font-weight: 200;
  border: 1px solid white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

#contactform input:focus,
#contactform textarea:focus {
    border-color: #00ffff;
    transition: 0.5s;
    outline: none;
}

#contactform input::placeholder,
#contactform textarea::placeholder {
  color: white;
  opacity: 0.8;
}

#submit {
  padding: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: 1px solid white;
  outline-offset: -1px;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  color: white;
  font-weight: 200;
  font-family: Futura;
  font-size: 15px;
  position: relative;
}

#submit:disabled {
  cursor: not-allowed;
}

#submit p {
  margin: 0;
  padding: 0;
}

#update {
  z-index: 2000;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  margin: 5px;
  height: 81%;
  position: absolute;
  font-size: 15px;
  text-align: center;
  color: white;
}

#update p {
  margin: 5;
  padding: 0;
  text-align: center;
}

#btn {
  width: 150px;
  border-radius: 0;
  border: 0;
  padding: 10px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-weight: 200;
  font-size: 13px;
  cursor: pointer;
  transition: 0.5s;
}

#btn:hover {
  border-color: #00ffff;
  transition: 0.5s;
}

#rectwrap {
  position: absolute;
}

.siz {
  font-size: 30px;
}

