body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes show{
  to{
    visibility:visible;
  }
}

@keyframes hide{
  to{
      visibility: hidden;
  }
}

.makeStyles-MainMessage-8 div span {
  animation: hide 0s ease 400ms forwards;
}

.makeStyles-SubMessage-9 div span {
  animation: show 0s 800ms forwards;
}

textarea {
  white-space: pre-wrap;
}

.grecaptcha-badge { visibility: hidden; }